<template>
    <div>
        <div>
            <h2>Custom Ad Copy</h2>
            <h3 class="custom-campaign-header">
                Playbook: {{ play.display_name }}
            </h3>
        </div>
        <div>
            <div>
                <div class="campaign-header">
                    <h4 class="custom-campaign-header">
                        Campaigns
                    </h4>
                    <span
                        class="continue-editing-button"
                        @click="toggleExpandAll">
                        {{ revertButtonText }}
                    </span>
                </div>
                <v-expansion-panel
                    v-for="(campaign, campaignIndex) in onboardingData.campaigns"
                    :key="campaign.remote_name"
                    :value="expanded"
                    expand>
                    <hr
                        v-if="campaignIndex !== 0"
                        class="creative-divider_2">
                    <v-expansion-panel-content
                        class="panel-content">
                        <template #header>
                            <v-layout align-center>
                                <img
                                    :src="require('@/assets/icons/folder.svg')"
                                    alt="campaign"
                                    class="fixed-size-image"
                                    style="margin-left: 12px;">
                                <h2>{{ campaign.name }}</h2>
                            </v-layout>
                        </template>
                        <hr class="creative-divider">
                        <v-expansion-panel
                            v-for="adSet in filteredAdSets(campaign.remote_name)"
                            :key="`${adSet.remote_name}-${campaign.remote_name}`"
                            :value="adSetExpanded[campaign.remote_name]"
                            expand>
                            <v-expansion-panel-content class="panel-content">
                                <template #header>
                                    <v-layout align-center>
                                        <img
                                            :src="require('@/assets/icons/adset.svg')"
                                            alt="ad"
                                            class="fixed-size-image"
                                            style="margin-left: 12px;">
                                        <h2>{{ adSet.name }}</h2>
                                    </v-layout>
                                </template>
                                <hr class="creative-divider_1">
                                <v-card class="custom-card">
                                    <div
                                        v-for="(adCreative) in filteredAds(adSet.remote_name)"
                                        :key="`${adSet.remote_name}-${adCreative.remote_name} `"
                                        class="custom-expansion-panel">
                                        <p>
                                            <img
                                                :src="require('@/assets/icons/ad.svg')"
                                                alt="adset"
                                                width="20px"
                                                height="20px"
                                                style="margin-right: 8px;">{{ adCreative.ad_name }}
                                        </p>
                                        <div>
                                            <label class="custom-label">Primary Text</label>
                                            <div class="field-wrapper">
                                                <token-editor
                                                    :ref="`primaryText-${adCreative.remote_name}-${findCreativeIndex(adCreative.ad_remote_name)}`"
                                                    :value="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].primaryText"
                                                    :label="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].ad_name"
                                                    :tokens="pickTokens()"
                                                    :max-length="2200"
                                                    :toggle-show="hasInventoryDependency"
                                                    class="custom-text-field"
                                                    @input="updateAdField($event, findCreativeIndex(adCreative.ad_remote_name), 'primaryText')"
                                                    @update-length="updateLength(findCreativeIndex(adCreative.ad_remote_name), 'primaryText', $event)" />
                                                <div class="tooltip-wrapper">
                                                    <span class="char-counter">{{ adCreatives[findCreativeIndex(adCreative.ad_remote_name)].primaryTextLength ?? 0 }}/2200</span>
                                                    <styled-tooltip
                                                        class="single-icon"
                                                        open-delay="0"
                                                        close-delay="0"
                                                        nudge-top="10"
                                                        position="top">
                                                        <template #content>
                                                            {{ "This will be used to populate the Meta's primary text field." }}
                                                        </template>
                                                        <icon
                                                            name="info"
                                                            color="#909FA8"
                                                            size="15" />
                                                    </styled-tooltip>
                                                </div>
                                            </div>
                                            <label class="custom-label">Headline</label>
                                            <div class="field-wrapper">
                                                <token-editor
                                                    :ref="`headline-${adCreative.remote_name}-${findCreativeIndex(adCreative.ad_remote_name)}`"
                                                    :value="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].headline"
                                                    :label="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].ad_name"
                                                    :tokens="pickTokens()"
                                                    :max-length="40"
                                                    :toggle-show="hasInventoryDependency"
                                                    class="custom-text-field"
                                                    @input="updateAdField($event, findCreativeIndex(adCreative.ad_remote_name), 'headline')"
                                                    @update-length="updateLength(findCreativeIndex(adCreative.ad_remote_name), 'headline', $event)" />
                                                <div class="tooltip-wrapper">
                                                    <span class="char-counter">{{ adCreatives[findCreativeIndex(adCreative.ad_remote_name)].headlineLength ?? 0 }}/40</span>
                                                    <styled-tooltip
                                                        class="single-icon"
                                                        open-delay="0"
                                                        close-delay="0"
                                                        nudge-top="10"
                                                        position="top">
                                                        <template #content>
                                                            {{ "This will be used to populate the Meta's headline field." }}
                                                        </template>
                                                        <icon
                                                            name="info"
                                                            color="#909FA8"
                                                            size="15" />
                                                    </styled-tooltip>
                                                </div>
                                            </div>
                                            <label class="custom-label">
                                                {{ adCreatives[findCreativeIndex(adCreative.ad_remote_name)].description ? 'Description' : 'Description (This field is not editable for this ad set)' }}
                                            </label>
                                            <div class="field-wrapper">
                                                <token-editor
                                                    :ref="`description-${adCreative.remote_name}-${findCreativeIndex(adCreative.ad_remote_name)}`"
                                                    :value="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].description ?? ''"
                                                    :label="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].ad_name"
                                                    :tokens="pickTokens()"
                                                    :max-length="40"
                                                    :toggle-show="hasInventoryDependency"
                                                    class="custom-text-field"
                                                    :readonly="!adCreatives[findCreativeIndex(adCreative.ad_remote_name)].description"
                                                    :style="{ opacity: adCreatives[findCreativeIndex(adCreative.ad_remote_name)].description ? 1 : 0.5 }"
                                                    @input="updateAdField($event, findCreativeIndex(adCreative.ad_remote_name), 'description')"
                                                    @update-length="updateLength(findCreativeIndex(adCreative.ad_remote_name), 'description', $event)" />
                                                <div class="tooltip-wrapper">
                                                    <span class="char-counter">{{ adCreatives[findCreativeIndex(adCreative.ad_remote_name)].descriptionLength ?? 0 }}/40</span>
                                                    <styled-tooltip
                                                        class="single-icon"
                                                        open-delay="0"
                                                        close-delay="0"
                                                        nudge-top="10"
                                                        position="top">
                                                        <template #content>
                                                            {{ "This will be used to populate the Meta's description field." }}
                                                        </template>
                                                        <icon
                                                            name="info"
                                                            color="#909FA8"
                                                            size="15" />
                                                    </styled-tooltip>
                                                </div>
                                            </div>

                                            <label class="custom-label">Call to Action</label>
                                            <div>
                                                <div class="layout align-center">
                                                    <div class="flex align-center v-select-wrapper">
                                                        <v-select
                                                            :ref="`cta-${adCreative.remote_name}-${findCreativeIndex(adCreative.ad_remote_name)}`"
                                                            v-model="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].callToAction"
                                                            :items="adCreatives[findCreativeIndex(adCreative.ad_remote_name)].allowedCTAs"
                                                            item-text="text"
                                                            item-value="value"
                                                            class="styled-field mb-3 ml-4 mr-4"
                                                            @input="updateAdField($event, findCreativeIndex(adCreative.ad_remote_name), 'callToAction')" />
                                                        <styled-tooltip
                                                            open-delay="0"
                                                            close-delay="0"
                                                            nudge-top="10"
                                                            position="top">
                                                            <template #content>
                                                                {{ "This will be used to populate Meta's call to action field." }}
                                                            </template>
                                                            <icon
                                                                name="info"
                                                                color="#909FA8"
                                                                size="15" />
                                                        </styled-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr class="creative-divider_3">
                                    </div>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>

            <div class="layout align-center">
                <back-button @click="$store.dispatch('goToPreviousStep')" />
                <div class="button-group ml-auto">
                    <span
                        class="skip-button"
                        @click="finishStep">
                        SKIP >
                    </span>
                    <styled-button
                        :disabled="!valid"
                        @click="finishStep">
                        CONTINUE
                    </styled-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import { mapState } from 'vuex';
import { get } from 'lodash';
import TokenEditor from '@/components/globals/fields/TokenEditor.vue';
import { adCopyTokens, AIATokens } from '@/components/onboarding/helpers/tokenConfig.js';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import Icon from '@/components/globals/Icon.vue';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';

export default {
    name: 'PlayCustomAdCopy',
    components: {
        StyledTooltip, Icon,
        BackButton,
        StyledButton,
        TokenEditor,
    },
    data() {
        return {
            valid: true,
            adCreatives: [],
            adCopyTokens,
            AIATokens,
            allPanelsExpanded: false,
            expandedPanels: [],
            expanded: null,
            adSetExpanded: {},
        };
    },
    computed: {
        ...mapState({
            adSets: (state) => state.onboarding.playAdSets,
            onboardingData: (state) => state.onboarding.data,
            playAdCreatives: (state) => state.onboarding.playAdCreatives,
            play: (state) => state.onboarding.play,
        }),
        hasInventoryDependency() {
            return this.play?.dependencies.includes('inventory');
        },
        isPageEdited() {
            return this.onboardingData.pageEdited;
        },
        revertButtonText() {
            return this.isPageEdited ? 'Revert to Original Copy' : 'Customize Copy';
        }
    },
    watch: {
        onboardingData: {
            immediate: true,
            handler(newVal) {
                // Initialize with all panels collapsed
                this.expandedPanels = newVal.campaigns.map(() => false);
            }
        },
        adCreatives: {
            deep: true,
            handler(adCreatives) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { adCreatives });
            }
        }
    },
    created() {
        const existingAdCreatives = get(this.onboardingData, 'adCreatives', null) || [];
        this.onboardingData.campaigns.forEach(campaign => {
            this.adSetExpanded[campaign.remote_name] = Array(this.filteredAdSets(campaign.remote_name).length).fill(false);
        });

        // Expand all panels if pageEdited is true
        if (this.onboardingData.pageEdited) {
            this.expanded = Array(this.onboardingData.campaigns.length).fill(true);
            this.onboardingData.campaigns.forEach(campaign => {
                this.adSetExpanded[campaign.remote_name] = Array(this.filteredAdSets(campaign.remote_name).length).fill(true);
            });
            this.allPanelsExpanded = true;
        } else {
            this.expanded = Array(this.onboardingData.campaigns.length).fill(false);
            this.onboardingData.campaigns.forEach(campaign => {
                this.adSetExpanded[campaign.remote_name] = Array(this.filteredAdSets(campaign.remote_name).length).fill(false);
            });
            this.allPanelsExpanded = false;
        }

        if (existingAdCreatives.length === this.playAdCreatives.length) {
            this.adCreatives = existingAdCreatives;
        } else {
            this.adCreatives = this.playAdCreatives?.map(adCreative => {
                return {
                    ...adCreative,
                    primaryTextLength: adCreative.primaryText?.length,
                    headlineLength: adCreative.headline?.length,
                    descriptionLength: adCreative.description?.length,
                };
            });
        }
    },
    methods: {
        findCreativeIndex(adRemoteName) {
            return this.playAdCreatives.findIndex(adCreative => adCreative.ad_remote_name === adRemoteName);
        },
        toggleExpandAll() {
            if (this.isPageEdited) {
                this.revertAll(); // Revert all changes
            } else {
                // Toggle panel expansion
                if (this.allPanelsExpanded) {
                    this.expanded = Array(this.onboardingData.campaigns.length).fill(false);
                    this.onboardingData.campaigns.forEach(campaign => {
                        this.adSetExpanded[campaign.remote_name] = Array(this.filteredAdSets(campaign.remote_name).length).fill(false);
                    });
                    this.allPanelsExpanded = false;
                } else {
                    this.expanded = Array(this.onboardingData.campaigns.length).fill(true);
                    this.onboardingData.campaigns.forEach(campaign => {
                        this.adSetExpanded[campaign.remote_name] = Array(this.filteredAdSets(campaign.remote_name).length).fill(true);
                    });
                    this.allPanelsExpanded = true;
                }
            }
        },
        revertAll() {
            this.adCreatives.forEach((adCreative, index) => {
                this.$set(this.adCreatives, index, {
                    ...adCreative,
                    primaryText: adCreative.originalPrimaryText,
                    headline: adCreative.originalHeadline,
                    description: adCreative.originalDescription,
                    callToAction: adCreative.originalCallToAction,
                });
            });

            // Commit reverted state to the store and reset pageEdited
            this.$store.commit(UPDATE_ONBOARDING_DATA, { pageEdited: false });
        },
        pickTokens() {
            const hasAIA = this.hasInventoryDependency;
            return hasAIA ? this.AIATokens : this.adCopyTokens;
        },
        filteredAds(adSetRemoteName) {
            return this.playAdCreatives.filter(ad => ad.ad_set_remote_name === adSetRemoteName);
        },
        filteredAdSets(campaignRemoteName) {
            return this.adSets.filter(ad => ad.campaign_remote_name === campaignRemoteName);
        },
        updateAdField(value, index, field) {
            if(value != null) {
                this.valid = true;
                const adCreative = this.adCreatives[index];
                this.$set(this.adCreatives, index, {
                    ...adCreative,
                    [field]: value,
                    [`${field}Length`]: value.length
                });
                // page has been edited set to true
                this.$store.commit(UPDATE_ONBOARDING_DATA, { pageEdited: true });
            }
        },
        updateLength(index, field, length) {
                const newAdCreatives = [...this.adCreatives];
                newAdCreatives[index][`${field}Length`] = length ?? 0;
                this.adCreatives = newAdCreatives;
        },
        finishStep() {
            this.$store.dispatch('setAdCopyComplete', true);
            this.$store.dispatch('goToNextStep');
        }
    }
};
</script>
<style>
.fixed-size-image {
    flex-basis: auto;
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.custom-expansion-panel {
    background-color: #fafafa;
    padding: 0 0 30px 30px;
}

.custom-campaign-header {
    padding-bottom: 16px;
}

.panel-content {
    background-color: #fafafa !important;
    padding: 0 0 0 13px;
}

.custom-card {
    background-color: #fafafa;
    margin: 24px;
}


.custom-text-field {
    flex-grow: 1;
    background-color: white !important;
    padding: 16px !important;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: auto;
}

.custom-label {
    font-weight: bold;
    display: block;
    margin: 0 0 5px 24px;
}

.field-wrapper {
    margin: 0 0 24px 24px;
    position: relative;
}

.char-counter {
    font-size: 0.8rem;
    color: #777;
    margin-right: 10px;
}

.creative-divider {
    border: 0;
    border-top: 1px solid #ccc;
    width: calc(100% + 12px);
    margin-left: -12px;
}

.creative-divider_1 {
    border: 0;
    border-top: 1px solid #ccc;
    width: calc(100% + 24px);
    margin-left: -24px;
}

.creative-divider_2 {
    border: 0;
    border-top: 1px solid #ccc;
    width: calc(100%);
}

.creative-divider_3 {
    border: 0;
    border-top: 1px solid #ccc;
    width: calc(100% + 100px);
    margin-left: -78px;
}

.v-expansion-panel__header__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.v-expansion-panel {
    padding-bottom: 0 !important;
}

.v-expansion-panel {
    box-shadow: none;
}

.skip-button {
    cursor: pointer;
    color: #51c1f7;
    font-weight: bold;
    margin-right: 15px;
}

.skip-button:hover {
    text-decoration: underline;
}

.button-group {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.continue-editing-button {
    cursor: pointer;
    color: #51c1f7;
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
    text-decoration: underline;
}
.campaign-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.field-wrapper {
    margin: 0 0 24px 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.char-counter {
    font-size: 0.8rem;
    color: #777;
    margin-right: 10px;
}

.tooltip-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
}


.v-select-wrapper {
    display: flex;
    align-items: center;
}

.styled-field {
    flex-grow: 1;
    min-width: 0;
}
</style>
